import React, { PropsWithChildren, useState } from "react";

interface PageContextType {
  closeMapModal: () => void
  openMapModal: () => void
  isMapModalVisible: boolean
}

export const PageContext = React.createContext<PageContextType>({
  isMapModalVisible: false,
  closeMapModal: () => { },
  openMapModal: () => { }
})

export default function PageProvider({ children }: PropsWithChildren) {

  const [isMapModalVisible, setIsMapModalVisible] = useState<boolean>(false)

  const closeMapModal = () => setIsMapModalVisible(false)
  const openMapModal = () => setIsMapModalVisible(true)

  return (
    <PageContext.Provider
      value={{
        closeMapModal,
        isMapModalVisible,
        openMapModal
      }}>

      {children}

    </PageContext.Provider>
  )
}